import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

const Container = 'relative flex flex-col mt-8 px-4 pt-8 pb-4 sm:p-0 sm:mb-6'
const Title = 'font-display font-bold text-2xl sm:text-center text-orange-900 mt-0 mb-6'
const cardImage = 'w-full rounded shadow-lg mb-6'
const desc = 'text-gray-900'

const PageProductsSection = () => {
  const data = useStaticQuery(graphql`
    query PageProductsSectionQuery {
      imageBrooks: file(relativePath: { eq: "products/brooks-equipment.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageFirePro: file(relativePath: { eq: "products/fire-protections.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageHandPort: file(relativePath: { eq: "products/hand-portable-extinguishers.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageHandPort1: file(relativePath: { eq: "products/hand-portable-extinguishers-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageHandPort2: file(relativePath: { eq: "products/hand-portable-extinguishers-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageJL: file(relativePath: { eq: "products/jl-industries.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageJL1: file(relativePath: { eq: "products/jl-industries-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageJL2: file(relativePath: { eq: "products/jl-industries-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageJL3: file(relativePath: { eq: "products/jl-industries-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageRestSys: file(relativePath: { eq: "products/restaurant-systems.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageWheeled: file(relativePath: { eq: "products/wheeled-extinguishers.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageWheeled1: file(relativePath: { eq: "products/wheeled-extinguishers-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageWheeled2: file(relativePath: { eq: "products/wheeled-extinguishers-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageGSA: file(relativePath: { eq: "logo/gsa-advantage-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const imageRestSys = data.imageRestSys.childImageSharp.fluid
  const imageHandPort = data.imageHandPort.childImageSharp.fluid
  const imageHandPort1 = data.imageHandPort1.childImageSharp.fluid
  const imageHandPort2 = data.imageHandPort2.childImageSharp.fluid
  const imageWheeled = data.imageWheeled.childImageSharp.fluid
  const imageWheeled1 = data.imageWheeled1.childImageSharp.fluid
  const imageWheeled2 = data.imageWheeled2.childImageSharp.fluid
  const imageFirePro = data.imageFirePro.childImageSharp.fluid
  const imageBrooks = data.imageBrooks.childImageSharp.fluid
  const imageJL1 = data.imageJL1.childImageSharp.fluid
  const imageJL2 = data.imageJL2.childImageSharp.fluid
  const imageJL3 = data.imageJL3.childImageSharp.fluid
  const imageGSA = data.imageGSA.childImageSharp.fluid

  return (
    <div>
      <div className='flex flex-col w-full bg-red-700 items-center font-body justify-center px-4 py-8 mx-auto'>
        <h1 className='font-display text-4xl text-white text-center'>Fire Safety Products</h1>
      </div>

      <div className='px-24 py-0 sm:px-4 md:px-8 sm:py-12'>
        <div className='sm:w-full w-11/12 xl:w-6/12 mx-auto mb-12'>
          <div className='flex flex-col w-full items-center font-body justify-center mx-auto mt-12 sm:mt-0'>
            <p className='text-gray-900 text-xl text-center'>South Bay Fire was recently awarded a Schedule 84 GSA Contract, #47QSWA20D0070.</p>

            <Img fluid={imageGSA} alt='GSA logo' className='w-64 mt-6 rounded shadow-md' />
          </div>

          <div className='flex sm:w-full w-10/12 justify-center mx-auto'>
            <a href='https://www.gsaadvantage.gov/advantage/ws/search/advantage_search?db=0&searchType=1&q=19:547QSWA20D0070&src=elib' target='_blank' rel='noopener noreferrer'>
              <button className="bg-orange-800 hover:bg-orange-700 text-white text-lg font-bold py-3 px-6 rounded-full border-b-4 border-orange-900 mt-8 sm:mb-6">
                View Product Catalog
              </button>
            </a>
          </div>

          <div className={Container}>
            <h2 className={Title}>Amerex Kitchen Fire Protection System</h2>

            <div className='flex sm:flex-col items-start'>
              <div className='sm:w-full md:w-4/12 w-1/2'>
                <Img fluid={imageRestSys} alt='Restaurant Systems' className={cardImage} />
              </div>

              <div className='sm:w-full md:w-8/12 w-1/2 ml-6 sm:ml-0'>
                <p className={desc}>KP is a staple in commercial kitchens around the world. To meet NFPA guidelines and ensure quality, Amerex stringently tests this product with Underwriters Laboratory to meet UL 300, Standard for Fire Testing of Fire Extinguishing Systems for Protection of Commercial Cooking Equipment. KP is designed to accommodate the needs of building and restaurant owners who utilize restaurant fire suppression systems to protect their valuable property and ensure the safety of the people working in the kitchens. From fine dining to fast food chains, the Amerex Kitchen Fire Protection system has the components to meet your needs with competitive pricing while never sacrificing quality.</p>

                <a href='https://fast.wistia.net/embed/iframe/03ogwsrm8e?controlsVisibleOnLoad=true&playerColor=666666&version=v1&videoHeight=272&videoWidth=480&volumeControl=true&autoPlay=true' target='_blank' rel='noopener noreferrer' className='block text-orange-900 underline mt-3'>Watch Video</a>
              </div>
            </div>

            <div className='w-full'>
              <Link to='/contact'>
                <button className="bg-orange-800 hover:bg-orange-700 text-white text-lg font-bold py-3 px-6 rounded-full border-b-4 border-orange-900 mt-8 sm:mb-6">
                  Get Free Quote
                </button>
              </Link>
            </div>
          </div>

          <div className={Container}>
            <h2 className={Title}>Amerex Hand Portable Fire Extinguishers</h2>

            <div className='flex flex-col items-start'>
              <div className='w-full grid grid-cols-3 sm:grid-cols-1 gap-6'>
                <Img fluid={imageHandPort} alt='Restaurant Systems' className={cardImage} />

                <Img fluid={imageHandPort1} alt='Restaurant Systems' className={cardImage} />

                <Img fluid={imageHandPort2} alt='Restaurant Systems' className={cardImage} />
              </div>

              <div className='w-full'>
                <p className={desc}>Amerex manufactures a full line of hand portable and wheeled fire extinguishers, both "compliance" (code required) and "specialty" types.</p>
              </div>
            </div>

            <div className='w-full'>
              <Link to='/contact'>
                <button className="bg-orange-800 hover:bg-orange-700 text-white text-lg font-bold py-3 px-6 rounded-full border-b-4 border-orange-900 mt-8 sm:mb-6">
                  Get Free Quote
                </button>
              </Link>
            </div>
          </div>

          <div className={Container}>
            <h2 className={Title}>Amerex Wheeled Fire Extinguishers</h2>

            <div className='flex flex-col items-start'>
              <div className='w-full grid grid-cols-3 sm:grid-cols-1 gap-6'>
                <Img fluid={imageWheeled} alt='Restaurant Systems' className={cardImage} />

                <Img fluid={imageWheeled1} alt='Restaurant Systems' className={cardImage} />

                <Img fluid={imageWheeled2} alt='Restaurant Systems' className={cardImage} />
              </div>

              <div className='w-full'>
                <p className={desc}>Amerex manufactures a large selection of wheeled units. Available in Dry Chemical, Wet Chemical, Gaseous Clean Agents, and Class D for metal fires.</p>
              </div>
            </div>

            <div className='w-full'>
              <Link to='/contact'>
                <button className="bg-orange-800 hover:bg-orange-700 text-white text-lg font-bold py-3 px-6 rounded-full border-b-4 border-orange-900 mt-8 sm:mb-6">
                  Get Free Quote
                </button>
              </Link>
            </div>
          </div>

          <div className={Container}>
            <h2 className={Title}>JL Industries Fire Protection</h2>

            <div className='flex flex-col items-start'>
              <div className='w-full grid grid-cols-3 sm:grid-cols-1 gap-6 mb-6'>
                <div>
                  <Img fluid={imageJL1} alt='Restaurant Systems' className={cardImage} />

                  <p className={desc}>Our Most Popular Painted Steel Cabinet with 10 Door Styles & 4 Trim Styles For Recessed, Semi Recessed or Surface-Mounted Installation.</p>
                </div>

                <div>
                  <Img fluid={imageJL2} alt='Restaurant Systems' className={cardImage} />

                  <p className={desc}>The Industry Standard for Durable, Aluminum Cabinets. Combine One of our 10 Door Styles and 7 Trim Selections for the Look you Require.</p>
                </div>

                <div>
                  <Img fluid={imageJL3} alt='Restaurant Systems' className={cardImage} />

                  <p className={desc}>Select One of the 7 Trim Styles of this Sleek, #4 Stainless Steel Finish Cabinet. Match the Cosmopolitan Cabinet to Other Stainless Building Components.</p>
                </div>
              </div>
            </div>

            <div className='w-full'>
              <Link to='/contact'>
                <button className="bg-orange-800 hover:bg-orange-700 text-white text-lg font-bold py-3 px-6 rounded-full border-b-4 border-orange-900 mt-8 sm:mb-6">
                  Get Free Quote
                </button>
              </Link>
            </div>
          </div>

          <div className={Container}>
            <h2 className={Title}>Potte Roemer / Fire Pro</h2>

            <div className='flex sm:flex-col items-start'>
              <div className='sm:w-full w-4/12'>
                <Img fluid={imageFirePro} alt='Restaurant Systems' className={cardImage} />
              </div>

              <div className='sm:w-full w-8/12 ml-6 sm:ml-0'>
                <p className={desc}>Over 75 years of innovation, quality and service. With its 110,000 sq. ft. ultra-modern headquarters and manufacturing facility in City of Industry (near Los Angeles), CA, and 6 other locations throughout the United States, Potter Roemer/Fire Pro is the leading producer and supplier of interior hose and standpipe equipment, cabinets, connections, extinguishers and valves to serve the fire protection industry. In addition, our Fire Pro product line Fire Pro produces premium firefighting equipment such as monitors, nozzles, and hydrants built specifically for the industrial, municipal, forestry, and marine markets.</p>
              </div>
            </div>

            <div className='w-full'>
              <Link to='/contact'>
                <button className="bg-orange-800 hover:bg-orange-700 text-white text-lg font-bold py-3 px-6 rounded-full border-b-4 border-orange-900 mt-8 sm:mb-6">
                  Get Free Quote
                </button>
              </Link>
            </div>
          </div>

          <div className={Container}>
            <h2 className={Title}>Brooks Equipment</h2>

            <div className='flex sm:flex-col items-start'>
              <div className='sm:w-full w-4/12'>
                <Img fluid={imageBrooks} alt='Restaurant Systems' className={cardImage} />
              </div>

              <div className='sm:w-full w-8/12 ml-6 sm:ml-0'>
                <p className={desc}>Brooks is part of a very exclusive group of fire equipment companies that operates in the US and abroad, and is also one of the oldest in the industry. The company offers cabinets, fire hose, nozzles, valves, alarms, signs, labels, tags, batteries, extinguishers, and emergency response products.</p>
              </div>
            </div>

            <div className='w-full'>
              <Link to='/contact'>
                <button className="bg-orange-800 hover:bg-orange-700 text-white text-lg font-bold py-3 px-6 rounded-full border-b-4 border-orange-900 mt-8 sm:mb-6">
                  Get Free Quote
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageProductsSection