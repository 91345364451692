import React from "react"

import HomeContainer from "../components/containers/HomeContainer"
import HeadData from "../data/HeadData"
import PageHeroHeader from '../components/headers/PageHeroHeader'
import HeaderNav from '../components/navigation/HeaderNav'
import PageProductsSection from "../components/sections/PageProductsSection"

export default () => (
  <HomeContainer>
    <HeadData title='Fire Safety Products Los Angeles' />

    <PageHeroHeader>
      <HeaderNav isHome />
    </PageHeroHeader>

    <PageProductsSection />
  </HomeContainer>
)